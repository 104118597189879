:root {
    --main-color: hsl(35, 100%, 49%);
    --main-grey:hsla(0, 0%, 100%, 0.502);
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: 'Oxanium', cursive, sans-serif;
}

h1 {
    font-weight: bold;
    font-size: 2.875em;
    font-size: clamp(38px, 100% + 1rem, 2.875em);
    color: var(--main-color);
    text-transform: uppercase;
}

.alt-h1 {
    color: white;
}

a:link {
    color:white;
    text-decoration: underline;
}
a:visited {
    color: var(--main-grey);
}

a:hover,
a:focus,
a:active {
    color: var(--main-color);
}

img {
    height: auto;
}

form input,
form textarea {
    background: none;
    color: white;
}

form input:not([type="submit"]),
form textarea {
    border: 0;
    border-bottom: 1px solid white;
    padding:1rem 0;
}

input[type="checkbox"] {
    width: auto;
    max-width: fit-content;
    display: inline-block;
}

input[type="submit"] {
    min-width: 200px;
    border: 1px solid white;
    padding:1rem;
    text-transform: uppercase;
}

input[type="submit"]:visited {
    background: hsla(35, 100%, 49%, 0.2);
}

input[type="submit"]:hover,
input[type="submit"]:focus {
    background: hsla(35, 100%, 49%, 0.5);
}


input[type="submit"]:active {
    background: var(--main-color);
}

input::placeholder,
textarea::placeholder {
    color: white;
}

input + input,
input + textarea,
textarea + input {
    margin-top:1rem;
}

form input:focus,
form textarea:focus {
    border: 1px solid var(--main-color);
    padding:1rem;
}
.next-service {
    text-transform: uppercase;
}
body,
#root,
.App {
    height: 100vh;
    max-height: 100vh;
    background-color: black;
    color: white;
    overflow-x: hidden;
}
.container {
    max-width: 1500px;
}
#mainContentCol {
    border-left: 1px solid white;
    border-right: 1px solid white;
}

/* side bars */

.col-lg-1.rigth {
    padding: 0px;
}

.leftBar,
.rightBar {
    height: 100vh;
}

.leftBar>div,
.rigthBar>div.sideInner {
    width: 100vh;
    position: fixed;
    -webkit-transform-origin: left top;
    -webkit-transform: rotate(-90deg) translateX(-100%);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 8.33333333vw;
    align-items: center;
    padding: 0 20px;
}

.rigthBar>div {
    padding-right: 0px !important;
}

#leftBar img {
    transform: rotate(90deg);
}

/* other stuff*/

.btn-primary {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 0px;
}

.btn-primary:hover {
    color: black;
    background-color: white;
    border: 1px solid white;
    border-radius: 0px;
}

.space-1 {
    height: 0.2em;
}

.space-2 {
    height: 0.5em;
}

.space-3 {
    height: 1em;
}

.space-4 {
    height: 2em;
}

.space-5 {
    height: 5em;
}


.contacto-telefonico-hp {
    font-size: 1.5em;
    color: #fc9200;
    text-decoration: none;
}

.contacto-telefonico-hp:hover {
    color: white;
}

.nav-btn {
    text-transform: uppercase;
}
.menu-button,
.menu-button::before,
.menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
}

.menu-button::before {
    content: '';
    margin-top: -8px;
}

.menu-button::after {
    content: '';
    margin-top: 8px;
}
.header-mobile.w-100,
#footer-bottom {
    width: calc(100% + var(--bs-gutter-x)) !important;
    position: relative;
    right: calc(var(--bs-gutter-x) / 2);
}

/** -- HEADER -- **/
.header,
.rigthBar > div.sideInner {
    z-index: 1;
}
.header {
    position: relative;
    z-index: 1;
}

.rigthBar > div.sideInner {
    position: fixed;
}

.header-mobile {
    gap:1rem;
    border-bottom:1px solid white;
    justify-content: space-between;
    overflow-x: hidden;
}
.header-mobile .navbar-brand {
    margin-left: calc(var(--bs-gutter-x) * .5);
}

.header-mobile .navbar-brand img {
    width: 100%;
    max-width: 260px;
    margin: .4rem 0;
}
.burger{
    background: #fc9200;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0 calc(var(--bs-gutter-x) * -.5);
}

.burger img{
    color: unset;
    background: unset;
    border:unset;
}

.burger-desktop {
    height: 100%;
}

.burger-desktop img{
    transform: rotate(90deg);
}
.burger img{
    width: 41px;
    filter: invert(100%);
}

.Menu-list{
    list-style-type: square;
}

.Menu-list li {
    color:#fc9200;
    font-size: 3em;
    font-size: clamp(38px, 100% + 1rem, 2.875em);
    font-weight: bold;
}

.Menu-list li a{
    text-decoration: none;
    color:#fff;
    text-transform: uppercase;
}

.Menu-list li a:hover{
    text-decoration: none;
    color:#fc9200;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
    text-decoration: none;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fc9200;
}

.navbar-dark .navbar-nav .nav-link.active {
    font-weight: bold;
}

.navbar-expand .navbar-nav {
    width: 100%;
    justify-content: space-around;
}

/** -- end HEADER -- **/

/** -- FOOTER -- **/

#footer-bottom {
    border-top:1px solid white;
    background: black;
    text-align: center;
}

#footer-bottom .container {
    padding: 2rem;

}
#footer-bottom .container > .row {
    justify-content: center;
    gap: 2rem;
}
#footer-bottom .container > .row > p {
    margin:0;
    color: #fc9200;
}

#footer-bottom .container > div > div:last-of-type{
    text-align: left;
    width: auto;
}
#footer-bottom div > a {
    display: block;
}

#footer-bottom .social-list {
    list-style: none;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

#header .social-list {
    list-style: none;
    display: flex;
    gap: 1rem;
    justify-content: center;
    
}

.social-list a {
color: #fc9200;
}

.social-list a:hover {
    color: #fff;
}

a{
    text-decoration: none;
}

#TERASTUDIO-brand {
    width: 100%;
    min-width: 170px;
    max-width: 200px;
}
/** -- end FOOTER -- **/

/** -- HOMEPAGE -- **/
.body {
    padding-top:4rem;
    padding-bottom:4rem;
}
.contacto-telefonico-hp:link {
    font-weight: bold;
    margin-bottom: 2rem;
    display: inline-block;
    color: var(--main-color);
    text-decoration: none;
}
.contacto-telefonico-hp:visited {
    color: var(--main-grey);
}
.contacto-telefonico-hp:hover,
.contacto-telefonico-hp:focus,
.contacto-telefonico-hp:active {
    color: white;
    text-decoration: underline;
}
/** -- end HOMEPAGE -- **/

/** -- SERVIÇOS -- **/
/*.servicos-block::after{
    content: url(assets/leandro.svg);
    position: fixed;
    bottom: 0;
    width: 83.33333333%;
    max-width: 1500px;
    transform: translateY(50%);
}*/

.servicos-block::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 76.666666%;
    max-width: 100%;
    height: 50%;
    max-height: 50px;
    z-index: 0;
    background-image: url(assets/leandro-2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    z-index: 0;
}

.top-service-nav {
    margin-bottom: 4rem;
}
.nav-ttl,
.nav-ttl a.next-service {
    font-weight: bold;
    color: var(--main-color);
    font-size: 1.250em;
}

.nav-ttl {
    gap: 1rem;
}

.nav-ttl p {
    text-align: center;
}

.top-service-nav .dot {
    width: 10px;
    height: 10px;
    background-color: white;
}

.top-service-nav .dot.active {
    background-color: var(--main-color);
}

.tituloServico{
    color:#fc9200;
}

/*.tituloServico h1 {
    font-size: 3em;
}*/
/** -- end SERVIÇOS -- **/

/** -- PORTFÓLIO -- **/
.intro-projetos {
    width: 100%;
}

.lista-projetos{
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;
}

.lista-projetos li button{
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid white;
    color:white;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.lista-projetos li:hover{
    background-color: hsla(35, 100%, 49%, 0.5);
}

.lista-projetos li.active,
.lista-projetos li:active{
    background-color: #fc9200;
}

.project-grid {
    width: 100%;
    max-width: 900px;
}
.project-grid > div {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 1rem;
    row-gap: 2rem;
    max-width: 100%;
}
.project-grid > div > div {
    padding: 0;
    width: 100%;
}
.project-grid > div > div a {
    display: block;
    height: 100%;
}
.project-grid > div > div figure {
    height: 100%;
    min-height: 180px;
    max-height: calc(50vw - 2rem);
    width: auto;
    margin-bottom: 0;
    overflow: hidden;
}
.project-grid > div > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 0;
}

.portfolio-imagem-projeto:link {
    text-decoration: none;
}

.project-grid .portfolio-titulo-projeto {
    margin:1rem 0 0 0;
}

.portfolio-gallery > .row {
    gap:1rem;
  }

  .portfolio-gallery > .row > div[class*="col-"] {
      padding:0;
    }

  .portfolio-gallery > .row > .col-lg-4 {
      height: calc(30vw - 4rem);
      max-height: 160px;
      max-width: 160px;
      overflow: hidden;
    }

  .portfolio-gallery > .row > .col-lg-4 img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

/** -- end PORTFÓLIO -- **/

/** -- CONTACTOS -- **/
.contact-block iframe {
    max-height: 175px;
}

.contact-list {
    max-width: calc(65% + 2rem);
    margin: 2rem auto;
    padding-right: 1rem;
    list-style: none;
    position: relative;
}

.contact-list li + li{
    margin-top: 1rem;
}

.contact-list li::before {
    color: var(--main-color);
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    transform: translateX(calc(-100% - 1rem));

}

.contact-list li.morada::before {
    content:'m';
}

.contact-list li.email::before {
    content:'e';
}

.contact-list li.tlml::before {
    content:'t';
}

.contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    max-width: 620px;
    margin: 0 auto;
}
.contact-form form {
    max-width: 100%;
}
.contact-block form .rgdp-block {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap:1rem;
    font-size: 80%;
}

.contact-block form .rgdp-block input {
    margin-top: .2rem;
}

.contact-block textarea {
    width: 100%;
}

/** -- end CONTACTOS -- **/

.service-image{
    width: 100%;
}
a.next-service{
    color:white;
    text-decoration: none;
}
a.next-service:hover{
    color:#fc9200;
}



img.portfolio-imagem-projeto img{
    object-fit: cover;
}

.portfolio-titulo-projeto{
    font-size: 1em;
    text-decoration: unset;
}

@media screen and (max-width:679px) {

    h1 {
        text-align: center;
    }
    
    .buttons-hp {
    flex-flow: column;
    width: 100%;
    margin-bottom:4rem;
    }

    .buttons-hp button {
        width: 100%;
    }

    /** -- HOMEPAGE -- **/
    .img-lm {
        display: none !important;
    }
    /** -- end HOMEPAGE -- **/
    
    /** -- SERVIÇOS -- **/
    .tituloServico{
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
    /** -- end SERVIÇOS -- **/

    /** -- PROJECTOS -- **/
    .portfolio-gallery > .row {
        gap:1rem;
    }
    /** -- end PROJECTOS -- **/

    /** -- CONTACTOS -- **/
    .contact-block form > div {
        flex-flow: column;
    }
    /** -- end CONTACTOS -- **/
}
@media screen and (min-width:680px) {

    #header {
        padding: 30px 0;
    }

    /** -- SERVIÇOS -- **/

    .project-block {
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    .intro-projetos {
        max-width: 400px;
    }
    .project-grid > div {
        grid-template-columns: repeat(2, calc(25% - 1rem));
        width: 100%;
    }

    .project-grid > div > div figure {
        height: 100%;
        max-height: 222px;
        overflow: hidden;
    }
    /** -- CONTACTOS -- **/
    .contact-block::before {
        content:'';
        width: 50%;
        height: 100vh;
        background-color: rgba(0,0,0,.5);
        position: fixed;
        top: 0;
        left: 50vw;
    }

    .contact-block > div {
        position:relative;
        z-index: 1;
    }
    
    .contact-block .contact-list {
        margin-left: 0;
    }
    .contact-block iframe {
        max-height: 300px;
    }
    
    .contact-block form {
        padding-left:1rem;
        padding-right:1rem;
    }
    
    .contact-block form > div {
        column-gap: 1rem;
    }
    
    .contact-block form > div:first-of-type > input {
        max-width: 31%;
    }

    .contact-form h1 {
        width: 100%;
    }

    .contact-form form .rgdp-block,
    .contact-form form div:last-of-type {
        justify-content: center;
    }

    /** -- end CONTACTOS -- **/
    
}

@media only screen and (min-height:750px) {
    .space-4 {
        height: 5em;
    }
}

@media screen and (min-width:992px) {
    .portfolio-gallery > .row {
        display: block;
    }

    .portfolio-gallery > .row .col-lg-8 {
        width: 71.6%;
        aspect-ratio: 1/1;
        overflow: hidden;
    }

    .portfolio-gallery > .row .col-lg-8 img {
        height: 100%;
        object-fit: cover;
        left: 1rem;
        position: relative;
    }
    .portfolio-gallery > .row > div[class*="col-"] {
        float: left;
        margin-top: 1.52rem;
    }
    .portfolio-gallery > .row > div[class*="col-"] + div[class*="col-"]{
        margin-left: 1rem;
    }

}

@media only screen and (max-width:1000px) {
    .space-4 {
        height: 2em !important;
    }
}

@media  screen and (min-width:1024px) {
    .project-grid > div {
        grid-template-columns: repeat(3, calc(25% - 1rem));
    }
    
}

/* fixes (cesar) after joana */
@media only screen and (min-width: 1200px) and (max-width:1400px){
    .project-grid > div {
        grid-template-columns: repeat(4, 25%);
    }
    .space-4 {
        height: 1em !important; 
    }
    #mainContentCol>#body{
        padding-top: 3rem;
        padding-bottom: 0;
    }
}


figure.img-fix,
figure.img-fix img{
    width: 100% !important;
    object-fit: cover;
}